import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import Title from "@/components/atoms/Title";
import Text from "@/components/atoms/Text";
import classNames from "classnames";
import Image from "next/image";
import { Autoplay, EffectFade, Pagination } from "swiper";
import Link from "next/link";

const Slider = (props) => {
  const { grayContainer = "true" } = props;
  const sliders = props.sliders || [];
  const styles = {
    sliderContainer:
      props.styles?.sliderContainer ||
      "flex items-center h-screen bg-cover bg-center bg-no-repeat",
  };
  const settings = {
    initialSlide: 0,
    slidesPerView: 1,
    effect: "fade",
    loop: true,
    modules: [Autoplay, Pagination, EffectFade],
    style: {
      "--swiper-navigation-size": "20px",
      "--swiper-theme-color": "#FFFFFF",
    },
    autoplay: {
      delay: 5000,
    },
  };
  return (
    <div className="relative">
      <Swiper
        {...settings}
        pagination={{ clickable: true }}
        className=" h-screen w-full"
      >
        {Array.isArray(sliders) &&
          sliders.map((s, k) => {
            return (
              <SwiperSlide key={k}>
                {s.url && (
                  <Link href={s.url} className="z-[3]">
                    <div className="absolute top-0 bottom-0 left-0 right-0 bg-main-slider to-transparent z-[2]" />
                    <Image
                      src={s.image.url}
                      fill
                      priority={k === 0 ? true : false}
                      className={classNames("object-cover", {
                        "hidden md:block": s?.image_mobile?.url,
                      })}
                      alt={s?.alt || `main-slider-${k + 1}`}
                      unoptimized
                    />
                    {s?.image_mobile?.url ? (
                      <Image
                        src={s.image_mobile.url}
                        fill
                        priority={k === 0 ? true : false}
                        className={classNames("object-cover md:hidden")}
                        alt={s?.alt || `main-slider-mobile-${k + 1}`}
                        unoptimized
                      />
                    ) : (
                      ""
                    )}
                    <div
                      className={classNames(
                        grayContainer === "true" &&
                          "absolute flex items-center h-screen left-0 right-0 z-10 bg-[#000]/20"
                      )}
                    >
                      <div className="base-container">
                        <Text className="slider-subtitle">{s.subtitle}</Text>
                        <Text className="slider-title">{s.title}</Text>
                      </div>
                    </div>
                  </Link>
                )}
                {!s.url && (
                  <>
                    <div className="absolute top-0 bottom-0 left-0 right-0 bg-main-slider to-transparent z-[2]" />
                    <Image
                      src={s.image.url}
                      fill
                      unoptimized
                      priority={k === 0 ? true : false}
                      className={classNames("object-cover", {
                        "hidden md:block": s?.image_mobile?.url,
                      })}
                      alt={s?.alt || `main-slider-${k + 1}`}
                    />
                    {s?.image_mobile?.url ? (
                      <Image
                        src={s.image_mobile.url}
                        fill
                        unoptimized
                        priority={k === 0 ? true : false}
                        className={classNames("object-cover md:hidden")}
                        alt={s?.alt || `main-slider-mobile-${k + 1}`}
                      />
                    ) : (
                      ""
                    )}
                    <div
                      className={classNames(
                        grayContainer === "true" &&
                          "absolute flex items-center h-screen left-0 right-0 z-10 bg-[#000]/20"
                      )}
                    >
                      <div className="base-container">
                        <Text className="slider-subtitle">{s.subtitle}</Text>
                        <Text className="slider-title">{s.title}</Text>
                      </div>
                    </div>
                  </>
                )}
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default Slider;

import defaultLogo from "@/public/images/icons/logo-blue.svg";
import close from "@/images/icons/close-gray.svg";
import Image from "next/image";
import classNames from "classnames";
import Link from "next/link";
import Button from "../atoms/Button";
import arrowBlueDown from "@/images/icons/arrow-blue-down.svg";

const BookingModal = ({
  active,
  setActive,
  hotelIconWhite,
  t,
  hotelFlyIconBlue,
  hotelIconBlue,
  hotelFlyIconWhite,
  roomsModalIsOpen,
  guestIcon,
  rooms,
  roomsText,
  adults,
  adultsText,
  setRoomsModalOpen,
  dateIcon,
  DatePicker,
  startDate,
  today,
  endDate,
  codeIcon,
  bookingUrl,
  setStartDate,
  setEndDate,
  BookingAttempt,
  GuestModal,
  addRoom,
  removeRoom,
  updateOccupancy,
  wrapperRef,
  setIsFromMobile,
  setModalMobileOpen,
  setPromoCode,
  promoCode
}) => {
  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  );
  return (
    <div
      className="fixed block lg:hidden  w-[100%] h-[100%] bg-main-blue-light mt-[36px]"
      style={{ zIndex: 50 }}
    >
      <div>
        <div className="grid grid-cols-12 relative mt-4">
          <div className="col-span-10 col-start-2">
            <div
              className="absolute top-[30px]"
              onClick={() => setModalMobileOpen(false)}
            >
              <Image src={close} />
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div>
            <Image src={defaultLogo} />
          </div>
        </div>
        <div className="base-container mt-10">
          <div
            className="col-span-2 flex border border-main-blue "
            style={{ borderRadius: "2px" }}
          >
            <div
              className={classNames(
                "w-3/6 grid h-[50px]",
                active === "1" ? "bg-main-blue" : "bg-white"
              )}
              onClick={() => setActive("1")}
            >
              <Image
                src={active === "1" ? hotelIconWhite : hotelIconBlue}
                width={22}
                height={22}
                alt="date"
                className="mx-auto h-1/2 mt-auto"
              />
              <p
                className={classNames(
                  "font-subtitle text-[16px]  my-auto   text-center uppercase",
                  active === "1" ? "text-white" : "text-main-blue"
                )}
              >
                HOTEL
              </p>
            </div>
            <div
              className={classNames(
                "w-3/6 ",
                active === "2" ? "bg-main-blue" : "bg-white"
              )}
              onClick={() => setActive("2")}
            >
              <Link
                href={t("common:hotel_fly_href")}
                className="grid h-full"
                target="_blank"
              >
                <Image
                  src={active === "2" ? hotelFlyIconWhite : hotelFlyIconBlue}
                  width={60}
                  height={26}
                  alt="date"
                  className="mx-auto h-1/2 mt-auto"
                />
                <p
                  className={classNames(
                    "font-subtitle text-[16px] my-auto   text-center uppercase",
                    active === "2" ? "text-white" : "text-main-blue"
                  )}
                >
                  {t("common:hotel_fly")}
                </p>
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div
              className="col-span-2  pl-3 border mt-6 border-main-blue py-1 "
              style={{ borderRadius: "2px 0 0 2px" }}
            >
              <label
                htmlFor="startDate"
                className="font-subtitle text-[12px] text-medium-grey uppercase"
              >
                Check-in
              </label>
              <div className="grow flex items-center">
                <Image src={dateIcon} alt="date" className="mr-2" />
                <DatePicker
                  id="startDate"
                  customInput={<DatepickerInput />}
                  closeOnScroll={true}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setTimeout(() => {
                      document.getElementById("endDateMobile").click();
                    }, 250);
                  }}
                  minDate={new Date(today.valueOf() + 86400000)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="font-subtitle text-[16px]  text-main-blue placeholder:text-main-blue w-full outline-none bg-transparent"
                />
              </div>
            </div>
            <div
              className="col-span-2  pl-3 border border-t border-r border-b border-l-0 mt-6 border-main-blue py-1"
              style={{ borderRadius: "0 2px 2px 0" }}
            >
              <label
                htmlFor="endDate"
                className="font-subtitle text-[12px] text-medium-grey uppercase"
              >
                Check-out
              </label>
              <div className="grow flex items-center">
                <Image src={dateIcon} alt="date" className="mr-2" />
                <DatePicker
                  id="endDateMobile"
                  customInput={<DatepickerInput />}
                  closeOnScroll={true}
                  onFocus={(e) => e.target.readOnly = true}
                  selected={endDate}
                  onChange={(date) => {
                    document.getElementById("btn-adults-mobile").click();
                    setEndDate(date);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date(startDate.valueOf() + 86400000)}
                  className="font-subtitle bg-transparent text-[16px] text-main-blue placeholder:text-main-blue w-full outline-none"
                />
              </div>
            </div>
          </div>
          <div
            className="col-span-3  pl-3 border border-main-blue py-1 relative mt-6 "
            onClick={() => setIsFromMobile(true)}
            ref={wrapperRef}
            style={{ borderRadius: "2px" }}
          >
            {roomsModalIsOpen && (
              <GuestModal
                rooms={rooms}
                addRoom={addRoom}
                removeRoom={removeRoom}
                updateOccupancy={updateOccupancy}
              />
            )}
            <label className="font-subtitle text-[12px] text-medium-grey uppercase">
              {t("common:booking.guests")}
            </label>
            <div className="grow flex justify-start items-center">
              <Image src={guestIcon} alt="date" className="mr-2" />
              <Button
                id="btn-adults-mobile"
                className="font-subtitle text-[16px] text-main-blue placeholder:text-main-blue w-full outline-none text-left"
                onClick={() => setRoomsModalOpen(!roomsModalIsOpen)}
              >
                <input
                  type="text"
                  value={`${rooms?.length} ${roomsText}, ${adults} ${adultsText}`}
                  readOnly
                  className="w-full bg-transparent"
                />
              </Button>
            </div>
          </div>
          <div className="relative flex justify-end mr-6">
            <div className="absolute top-[-20px]">
              <Image src={arrowBlueDown} />
            </div>
          </div>
          <div
            className="col-span-3  pl-3 border mt-6 border-main-blue py-1 "
            style={{ borderRadius: "2px" }}
          >
            <label className="font-subtitle text-[12px] text-medium-grey uppercase">
              PROMOCODE
            </label>
            <div className="flex">
              <Image src={codeIcon} alt="promo" className="mr-2" />
              <input
                type="text"
                name="promocode"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder={t("common:booking.promo")}
                className="font-subtitle text-[16px] text-main-blue placeholder:text-main-blue w-full outline-none bg-transparent"
              />
            </div>
          </div>
          <div className="col-span-12  mt-8 lg:ml-0 lg:col-span-2">
            <Link
              href={bookingUrl}
              className="base-button bg-main-blue font-subtitle font-medium text-white px-0 w-full text-center  py-4"
              onClick={() => {
                BookingAttempt.post({
                  arrivalDate: startDate,
                  departureDate: endDate,
                  bookingDate: new Date(),
                  totalAdults: parseInt(adults),
                });
              }}
            >
              {t("common:booking.book_now")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;

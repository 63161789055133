import bg from "@/images/bgs/bg-newsletter.webp";
import Image from "next/image";
import Link from "next/link";
import Title from "../atoms/Title";
import arrow from "@/images/icons/newsletter-arrow.svg";
import { useTranslation } from "next-i18next";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useState } from "react";

const Newsletter = (props) => {
  const { gtmFormId = "" } = props;
  const { t } = useTranslation("common");
  const locale = t("common:locale")
  const mailchimpUrl =
    "https://goldenparnassusresortspa.us21.list-manage.com/subscribe/post?u=88b6b539135b5e68d86e41ffb&amp;id=514cf55da3&amp;f_id=00e8e0e6f0";



  const CustomForm = ({ status, message, onValidated }) => {
    let email, fname, lname, country, acceptTerms, sitio, idioma;

    const [errors, setErrors] = useState({
      fname: false,
      lname: false,
      email: false,
      country: false,
      acceptTerms: false
    })

    const submit = () => {
      const newErrors = {
        fname: fname.value.length === 0,
        lname: lname.value.length === 0,
        email: email.value.length === 0,
        country: country.value.length === 0,
        acceptTerms: !acceptTerms.checked
      }

      if (Object.values(newErrors).some(error => error)) {
        setErrors(newErrors)
      } else {
        onValidated({
          EMAIL: email.value,
          COUNTRY: country.value,
          FNAME: fname.value,
          LNAME: lname.value,
          MERGE4: acceptTerms.checked,
          SITIO: sitio.value,
          IDIOMA: idioma.value,
        })
        if (status === 'success') {
          setErrors({
            fname: false,
            lname: false,
            email: false,
            country: false,
            acceptTerms: false
          })
        }
      }
    }

    return (
      <>
        <form
          className="base-container base-grid"
          onSubmit={(event) => event.preventDefault()}
        >
          <div className="col-span-12 flex justify-center">
            {status === "error" && (
              <div
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === "success" && (
              <div
                style={{ color: "green" }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
          </div>
          <div className='hidden'>
            <input
              type={'text'}
              name="SITIO"
              ref={node => (sitio = node)}
              placeholder="Sitio"
              className="newsletter-input hidden"
              defaultValue={locale == 'es' ? 'https://www.goldenparnassusresortspa.com/es' : 'https://www.goldenparnassusresortspa.com/'}
            />
            <input
              type={'text'}
              name="IDIOMA"
              ref={node => (idioma = node)}
              placeholder="Idioma"
              className="newsletter-input hidden"
              defaultValue={locale}
            />
          </div>
          <div className="col-span-full md:col-span-2">
            <input
              type={"text"}
              ref={(node) => (country = node)}
              name="COUNTRY"
              placeholder={t("common:footer.newsletter.country")}
              className="newsletter-input"
              autoComplete="country"
            />
            {errors.country && (
              <span className="text-xs text-red-500">
                {' '}
                {t('common:footer.newsletter.error-required')}
              </span>
            )}
          </div>
          <div className="col-span-full md:col-span-3">
            <input
              type={"text"}
              ref={(node) => (fname = node)}
              name="FNAME"
              placeholder={t("common:footer.newsletter.name")}
              className="newsletter-input"
              autoComplete="given-name"
            />
            {errors.fname && (
              <span className="text-xs text-red-500">
                {' '}
                {t('common:footer.newsletter.error-required')}
              </span>
            )}
          </div>
          <div className="col-span-full md:col-span-3">
            <input
              type={"text"}
              ref={(node) => (lname = node)}
              name="LNAME"
              placeholder={t("common:footer.newsletter.lastname")}
              className="newsletter-input"
              autoComplete="family-name"
            />
            {errors.lname && (
              <span className="text-xs text-red-500">
                {' '}
                {t('common:footer.newsletter.error-required')}
              </span>
            )}
          </div>
          <div className="col-span-full md:col-span-3">
            <input
              type={"email"}
              name="EMAIL"
              ref={(node) => (email = node)}
              placeholder={t("common:footer.newsletter.email")}
              className="newsletter-input"
              autoComplete="email"
            />
            {errors.email && (
              <span className="text-xs text-red-500">
                {' '}
                {t('common:footer.newsletter.error-required')}
              </span>
            )}
          </div>
          <div className="col-span-full md:col-span-1 text-center">
            <button className="pt-4 md:pt-0" type="submit" onClick={submit} id={gtmFormId}>
              <span className="md:hidden mr-4 align-middle font-subtitle text-main-orange underline">
                {t("common:footer.newsletter.suscribe")}
              </span>
              <Image src={arrow} alt="submit" className="inline-block" />
            </button>
          </div>
        
       
          <div className="col-span-full flex justify-center pt-10 ">
          <input
              id="checkbox-terms"
              type={'checkbox'}
              className="self-center mr-2"
              ref={node => (acceptTerms = node)}
              name="acceptTerms"
              required
            />
            <label htmlFor="checkbox-terms" className="font-subtitle text-main-grey">
              {t("common:footer.newsletter.terms")}
              <Link
                href={t("routes:/privacy-policy")}
                target="_blank"
                className="ml-1 text-main-blue-king"
              >
                {t("common:footer.newsletter.terms_link")}
              </Link>
            </label>
          </div>
          </form>
      </>
    );
  };
  return (
    <section
      className="base-bg relative"
    >
      <div className="absolute w-full h-full">
        <Image
          className="w-full h-full object-cover"
          src={bg}
          alt="newsletter-background"
        />
      </div>
      <div className="base-container relative pt-20 pb-10">
        <Title
          tag="h3"
          className="font-subtitle text-main-orange text-5xl mb-10 md:mb-20 text-center font-light"
        >
          {t("common:footer.newsletter.title")}
          <span className="font-title text-main-blue text-[46px]">
            {t("common:footer.newsletter.title_2")}
          </span>
        </Title>
        <MailchimpSubscribe
          url={mailchimpUrl}
          style={{ margin: "auto" }}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </div>
    </section>
  );
};

export default Newsletter;
